

























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'Upload'
})
export default class Upload extends Vue {
  public get locale () {
    return AppModule.locale
  }
}
